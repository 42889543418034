<template lang="pug">
  .page.options-page
    actions-line
      template(v-slot:left)
        router-link.item(:to="listUrl")
          el-button(
            v-loading="loading"
            type="primary"
            icon="el-icon-back"
          ) К списку
        el-button.item(
          v-loading="loading"
          type="danger"
          icon="el-icon-delete"
          @click="openDeleteModal()"
        ) Удалить
      template(v-slot:center)
        locks(v-if="lockId && locks && version" ref="locks" :check-url="`${request}/${$route.params.id}/check`" :lock-id="lockId" :locks="locks" :version="version")
      template(v-slot:right)
        a.item(v-if="frontUrlPrefix && postForm.slug && published" :href="viewUrl" target="_blank")
          el-button(
            v-loading="loading"
            type="primary"
            icon="el-icon-view"
          ) Смотреть на сайте
        template(v-if="hasManagePermissions")
          el-button.item(
            v-if="frontUrlPrefix && postForm.slug"
            v-loading="loading"
            type="success"
            icon="el-icon-time"
            :disabled="published && !isChanged"
            @click="openPreview"
          ) Предпросмотр
          el-button.item(
            v-loading="loading"
            type="success"
            icon="el-icon-top"
            :disabled="!isChanged"
            @click="submitForm"
          ) {{ loading && !dataLoading ? 'Сохранение' : 'Сохранить' }}
        template(v-else)
          el-tooltip.item(effect="light" content="У вас нет прав для сохранения данной страницы")
            el-button(
              v-loading="loading"
              icon="el-icon-top"
            ) Сохранить
    el-form.page__container.options-page__container(ref="postForm" :model="postForm" :rules="rules")
      .page__body(v-show="!dataLoading")
        tab-fields(:fields="fields" v-model="postForm")

    preview-dialog(ref="previewDialog" :link="viewUrl")

    back-to-top
</template>

<script>

  import TabFields from '@/components/fields/TabFields'
  import ActionsLine from '@/components/PagePartials/ActionsLine'
  import PreviewDialog from '@/components/PagePartials/PreviewDialog'
  import BackToTop from '@/components/BackToTop'

  // import {validURL} from '@/utils/validate';
  import { default as request, catchRequest, notifyRequest, notifyErrorRequest } from '@/utils/api'
  import SaveRoutes from '@/views/Templates/SaveRoutes'
  import Locks from '@/components/Locks/index'

  export default {
    name: 'EntityEditPage',
    extends: [SaveRoutes],
    components: {
      TabFields,
      ActionsLine,
      PreviewDialog,
      BackToTop,
      Locks
    },
    data() {
      const validateRequire = (rule, value, callback) => {
        if (value === '') {
          this.$message({
            message: rule.field + ' is required',
            type: 'error',
          })
          callback(new Error(rule.field + ' is required'))
        } else {
          callback()
        }
      }
      return {
        postForm: {},
        published: false,
        request: this.$route.meta.request,
        loading: true,
        dataLoading: true,
        fields: [],
        rules: {},
        frontUrlPrefix: '#',
        formCache: '',
        previewModelOpened: false,

        lockId: null,
        locks: null,
        versions: null,
        skipVersionCheck: false,
        viewUrl: ''
      }
    },
    computed: {
      model() {
        return this.$route.meta.model
      },
      hasManagePermissions() {
        return this.$store.getters.permissions.includes(`manage ${this.model.replace(/-/g, '_')}`)
      },
      isChanged() {
        return this.formCache !== JSON.stringify(this.postForm)
      },
      listUrl() {
        return this.$route.fullPath.replace(/edit\/\d+/, 'list')
      },
      lang() {
        return this.$store.getters.language
      },
      currentPublished() {
        return this.postForm.status === 'publish' || !this.postForm.status
      }
    },
    watch: {
      'postForm.slug': function() {
        this.viewUrl = !this.frontUrlPrefix || !this.postForm.slug ? '' : this.frontUrlPrefix.replace(/\/$/, '') + '/' + this.postForm.slug.replace(/^\//, '')
        this.$store.commit('page/SET_VIEW_URL', this.viewUrl)
      }
    },
    async created() {
      const fetchedData = await this.fetchData()
      // console.log(fetchedData);
      this.postForm = fetchedData.values
      this.published = this.currentPublished
      this.formCache = JSON.stringify(this.postForm)
      this.fields = fetchedData.fields
      this.frontUrlPrefix = fetchedData.front_url_prefix
      this.lockId = fetchedData.lockId
      this.version = fetchedData.version || { version: 0 }
      this.locks = fetchedData.locks

      this.addFrontPrefixToSlugs()

      this.loading = false
      this.dataLoading = false
    },
    methods: {
      addFrontPrefixToSlugs() {
        this.fields.forEach(el => {
          if (el.type === 'slug') {
            el.frontUrlPrefix = (this.frontUrlPrefix || '').replace(/\/$/, '') + '/'
          }
        })
      },
      openDeleteModal() {
        const id = this.$route.params.id
        const item = JSON.parse(JSON.stringify(this.postForm))
        item.id = id
        if (this.request.includes('__links')) {
          this.$confirm(`Данный документ будет удален из всех разделов, в которых он находится. Удалить навсегда "${item.title}" (ID: ${item.id})?`, 'Подтвердите удаление', {
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отменить',
            // type: 'warning'
          }).then(() => {
            this.deleteItem(id)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: 'Удаление отменено'
            })
          })
          return
        }
        this.$confirm(`Удалить навсегда "${item.title}" (ID: ${item.id})?`, 'Подтвердите удаление', {
          confirmButtonText: 'Удалить',
          cancelButtonText: 'Отменить',
          // type: 'warning'
        }).then(() => {
          this.deleteItem(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Удаление отменено'
          })
        })
      },
      async deleteItem(id) {
        const response = await notifyRequest({
          url: `${this.request}/${id}`,
          method: 'delete'
        }, 'Объект успешно удален')
        setTimeout(() => {
          window.location.href = this.listUrl
        }, 3000)
      },
      async openPreview() {
        if (this.$refs.previewDialog.isLoading()) {
          return
        }
        this.$refs.previewDialog.showLoading()
        const data = JSON.parse(JSON.stringify(this.postForm))
        const responseData = await notifyErrorRequest({
          url: `${this.request}/${this.$route.params.id}/preview`,
          method: 'put',
          data: { data: data },
        }, 'Ошибка при проверке данных')
        if (responseData && responseData.data && responseData.data.version) {
          this.$refs.previewDialog.open(responseData.data.version)
        } else {
          this.$refs.previewDialog.close()
        }
        /*
        this.$confirm('Данный функционал находится в разработке', 'Предпросмотр несохраненной страницы', {
          confirmButtonText: 'ОК'
          // type: 'warning'
        })
        */
      },
      async fetchData() {
        this.dataLoading = true
        const data = await notifyErrorRequest({
          url: `${this.request}/${this.$route.params.id}`,
          method: 'get'
        })
        this.dataLoading = false
        return data
      },
      async storeData(data, version) {
        // console.log(this.loading);
        this.loading = true
        const responseData = await notifyRequest({
          url: `${this.request}/${this.$route.params.id}`,
          method: 'put',
          data: { data: data, version: version },
        }, 'Объект успешно сохранен')
        if (responseData.errorCode && responseData.errorCode === 1) {
          this.$confirm('После того, как вы открыли данную страницу, в нее были внесены и сохранены изменения другими пользователями или вами в другой вкладке.' +
            ' Если вы нажмете "Да", вы перезапишите все эти изменения текущим состоянием данной страницы.', 'Перезаписать изменения?', {
              confirmButtonText: 'Да',
              cancelButtonText: 'Нет',
            // type: 'warning'
            }).then(() => {
              this.storeData(data, 'ignore')
            }).catch(() => {
              this.$message({
                type: 'info',
                message: 'Сохранение отменено'
              })
              this.loading = false
            })
          return
        }
        this.loading = false
        if (responseData && !responseData.errors && !responseData.error) {
          this.published = this.currentPublished
          this.formCache = JSON.stringify(this.postForm)
          this.version = responseData.version
        }
        return responseData
      },
      async submitForm() {
        this.storeData(this.postForm, this.version.version)
      },
    },
  }
</script>
<style lang="sass">
  @import "@/assets/sass/mixin.scss"
</style>
